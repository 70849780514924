<template>
  <div class="container page__inner">
    <div class="page__content--wider">
      <div class="page__head border-0">
        <div
          class="
            page__head__container
            align-items-center
            justify-content-between
          "
        >
          <div class="page__head__text">
            <h1 class="page__head2__title">Underwriter General Dashboard</h1>
          </div>
          <div class="page__head__actions">
            <div class="d-flex w-100">
              <button
                type="button"
                data-toggle="modal"
                data-target="#assignWriter"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="setUpForm(null, true)"
                v-if="selectedLoans.length > 0"
              >
                <span class="d-inline-flex mr-2">Assign Underwriter</span>
              </button>
              <button
                type="button"
                data-toggle="modal"
                data-target="#reassignWriter"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="setUpForm(null, true)"
                v-if="selectedLoans.length > 0"
              >
                <span class="d-inline-flex mr-2">Reassign Underwriter</span>
              </button>
              <button
                type="button"
                @click="switchShowAssigned()"
                :disabled="isLoading"
                class="
                  mx-2
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
              >
                <span class="d-inline-flex mr-2">{{
                  fetchAccount.isAssigned ? "Show Unassigned" : "Show Assigned"
                }}</span>
              </button>
              <button
                type="button"
                class="
                  button
                  page__body__header__button
                  button--grey
                  text--capital
                "
                @click="toggleFilterModal"
                style="width:150px"
              >
                <span class="d-inline-flex mr-2">filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="page__head_subtitle">
        <p class="page__head2__subtitle">
          {{
            fetchAccount.isAssigned
              ? "where loan is assigned"
              : "where loan is not assigned"
          }}
        </p>
      </div>

      <div class="page__body">
        <div style="display:flex; width:50%; font-size:14px" v-if="filterText">
          <div style="width:80%">
            {{ filterText }}
          </div>
          <div style="display:flex;width:10%">
            <span style="cursor:pointer;font-size:17px" @click="editFilter">
              <i style="padding-right:5px" class="bi bi-pencil-square"></i>
            </span>
            <span style="cursor:pointer;font-size:17px" @click="deleteFilter">
              <i style="padding-left:5px" class="bi bi-x-circle-fill"></i>
            </span>
          </div>
        </div>
        <table class="customtable w-100">
          <thead>
            <tr>
              <th></th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Full Name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 12%"
              >
                Loan Account Number
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Loan Product
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Loan Amount
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 12%"
              >
                Approval Level
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 12%"
              >
                Assigned To
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 10%"
              >
                Assignment Date
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 17%"
              ></th>
            </tr>
          </thead>
          <tbody v-if="userIsNotAuthorised">
            <tr>
              <td colspan="12">
                <div
                  class="
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p5
                  "
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5"
                    >{{ errorResponse }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="loan in allLoans" :key="loan.loanAccountKey">
              <td>
                <input
                  type="checkbox"
                  class="form-"
                  v-model="loan.selected"
                  @click="toggleSelected(loan)"
                  style="margin-right: 10px;"
                />
                <span class="checkmark"></span>
              </td>
              <td>
                <!-- <a
                  href="underwritingdetails.html"
                  class="table__body__text table__text text--capital text--primary"
                  ></a
                > -->
                {{ loan.customerName }}
              </td>

              <td style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">{{
                  loan.loanId
                }}</span>
              </td>
              <td style="padding-right: 15px;">
                <span class="table__body__text table__text text--capital" style="word-break: break-word;">{{
                  loan.loanProductName
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital"
                  >₦{{ loan.loanAmount | formatCurrencyPipe }}</span
                >
              </td>
              <td>
                <span
                  class="
                    table__body__text table__text
                    text--capital text--primary
                  "
                  >{{ loan.currentApprovalLevelName }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  loan.currentUnderwriter
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  loan.assignmentDate | moment
                }}</span>
              </td>

              <td style="padding-right: 0 !important;">
                <div class="align-self-center dropdown">
                  <button
                    class="
                      button button--grey
                      text--capital text--small
                      d-flex
                      align-items-center
                    "
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#reassignWriter"
                      @click="setUpForm(loan, false)"
                      v-if="
                        fetchAccount.isAssigned && userCanAssign
                      "
                      >Reassign Underwriter</a
                    >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#assignWriter"
                      @click="setUpForm(loan, false)"
                      v-if="loan.underwritingStatus.toLowerCase() === 'not assigned' && userCanAssign"
                      >Assign Queue</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination" v-if="!userIsNotAuthorised">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <!-- <span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >
              <span class="d-inline-block text--primary" v-else>{{
                !paging.hasPreviousPage
                  ? paging.pageIndex
                  : paging.pageSize * (paging.pageIndex - 1) + 1
              }}</span>
              to -->
              <span class="d-inline-block text--primary mr-3">
                <label for="">
                  <select
                    v-model.number="fetchAccount.pageSize"
                    class="select select--lg select--page"
                    @change="getLoansWithUnderwritingInfo()"
                  >
                    <option
                      v-for="psize in pagesizes"
                      :key="psize.id"
                      :id="psize.id"
                      :selected="fetchAccount.pageSize"
                    >
                      {{ psize.size }}
                    </option>
                  </select>
                </label>
              </span>

              <span class="d-inline-block text--primary">{{
                paging.currentPage || paging.pageIndex
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.totalPages
              }}</span>
              <!-- of
              <span class="d-inline-block text--primary mr-3">{{ paging.totalCount }}</span> -->
            </div>
            <div
              class="
                pagination__buttons
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <!-- <button
                class="button text--regular button--sm ml-2 button--primary text--white"
              >
                1
              </button>
              <button class="button text--regular button--sm ml-2">2</button>
              <button class="button text--regular button--sm ml-2">3</button> -->
              <button
                class="button text--regular button--sm ml-2"
                v-for="num in paging.totalPages"
                :key="num"
                :class="{
                  'button--primary text--white': (paging.pageIndex === num || paging.currentPage === num),
                }"
                :disabled="paging.pageIndex === num"
                @click="changePage(num)"
              >
                {{ num }}
              </button>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      @reload="reloadFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @alertError="alertError"
      :filterHeaderName="'Underwriting General Dashboard'"
      filterCategory="underwriting_general"
    />

    <div
      class="modal modal__confirm"
      id="assignWriter"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Assign Queue
              </span>
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="assignModalCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__content">
            <div class="modal__head border-bottom">
              <h5 class="modal__head__title text--capital">
                Select a queue
              </h5>
            </div>
            <div class="modal__body">
              <form @submit="saveAssignForm">
                <!-- <div class="form__item">
                  <label class="form__label">Select Under writer</label>
                  <select
                    class="select select--lg"
                    v-model="form.approverUserKey"
                    v-bind:class="{
                      'input-required': formErr.approverUserKey
                    }"
                  >
                    <option value="" disabled selected
                      >Select any under writers</option
                    >
                    <option
                      v-for="user in allUsers"
                      :key="'ass' + user.userKey"
                      :value="user.userKey"
                      >{{ user.userFirstName }} {{ user.userLastName }}
                    </option>
                  </select>
                </div> -->
                <!-- <SearchSelectInput
                  :label="'Select a queue'"
                  :err="formErr.queueKey"
                  :formIsCleared="formCleared"
                  v-model="form.queueKey"
                >
                </SearchSelectInput> -->

                <div class="form__item">
                  <label class="form__label">Select queue</label>
                  <select
                    class="select select--lg"
                    v-model="form.queueKey"
                    v-bind:class="{
                      'input-required': formErr.queue
                    }"
                  >
                    <option value="" disabled selected
                      >Select any queue</option
                    >
                    <option
                      v-for="(queue, index) in allQueues"
                      :key="index"
                      :value="queue.queueKey"
                      >{{ queue.queueName }}
                    </option>
                  </select>
                </div>

                <!-- <div class="form__item">
                  <label
                    for="hasSecondaryApproval"
                    class="
                      d-block
                      has-checkbox
                      text--black text--500 text--regular
                    "
                    >Has Secondary Approval
                    <input
                      type="checkbox"
                      id="hasSecondaryApproval"
                      class="form-"
                      v-model="hasSecondaryApproval"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <SearchSelectInput
                  v-if="hasSecondaryApproval"
                  :label="'Select secondary underwriter'"
                  :formIsCleared="formCleared"
                  v-model="form.secondaryApprover"
                >
                </SearchSelectInput> -->

                <!-- <div class="form__item">
                  <label class="form__label">Select Approver Type</label>
                  <select
                    class="select select--lg"
                    v-model="form.approverType"
                    v-bind:class="{
                      'input-required': formErr.approverType,
                    }"
                  >
                    <option value="" disabled selected>
                      Select any under approver types
                    </option>
                    <option
                      v-for="approvalType in approverTypes"
                      :key="'ass' + approvalType"
                      :value="approvalType"
                    >
                      {{ approvalType | pascalCaseToString }}
                    </option>
                  </select>
                </div> -->
                <!-- <div class="form__item">
                  <label class="form__label">You selected</label>
                  <p class="text--regular text--bold text--black text--capital">
                    Babtunde Olofin
                  </p>
                </div> -->
                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      v-if="!isSaving"
                    >
                      Assign Queue
                    </button>
                    <a
                      role="button"
                      class="
                        button
                        form__button form__button--lg
                        w-25
                        save-changes
                      "
                      v-if="isSaving"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      v-if="!isSaving"
                      data-dismiss="modal"
                      role="button"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                      @click="resetForm"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal__confirm"
      id="reassignWriter"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                Reassign Underwriter
              </span>
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="reassignModalCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
          </div>
          <div class="modal__content">
            <div class="modal__head border-bottom">
              <h5 class="modal__head__title text--capital">
                Select an underwriter
              </h5>
              <!-- <div
                class="underwriter-form form__item mt-5"
                v-if="
                  singleSelectedLoan &&
                  Object.keys(singleSelectedLoan).length !== 0
                "
              >
                <label for="" class="form__label"
                  >First Approval Name:
                  {{
                    singleSelectedLoan
                      ? singleSelectedLoan.firstApproverName
                      : ""
                  }}</label
                >
              </div> -->
              <!-- <div
                class="underwriter-form form__item"
                v-if="
                  singleSelectedLoan &&
                  Object.keys(singleSelectedLoan).length !== 0 &&
                  singleSelectedLoan.approvalType
                "
              >
                <label
                  for=""
                  class="form__label"
                  v-if="
                    singleSelectedLoan.approvalType.toLowerCase() ===
                    'secondary approval'
                  "
                  >First Approval Level: Primary Approval</label
                >
                <label for="" class="form__label" v-else></label>
              </div> -->
            </div>
            <div class="modal__body">
              <form @submit.prevent="saveReAssignForm">
                <SearchSelectInput
                  :label="'Select underwriter'"
                  :err="reassignForm.userKey"
                  :formIsCleared="formCleared"
                  v-model="reassignForm.userKey"
                >
                </SearchSelectInput>

                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="
                        button
                        form__button form__button--lg
                        w-30
                        save-changes
                      "
                      v-if="!isSaving"
                    >
                      Reassign Underwriter
                    </button>
                    <a
                      role="button"
                      class="
                        button
                        form__button form__button--lg
                        w-25
                        save-changes
                      "
                      v-if="isSaving"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      v-if="!isSaving"
                      data-dismiss="modal"
                      role="button"
                      class="
                        modal__close
                        form__action__text form__action__text--danger
                        text--capital
                        ml6
                      "
                      @click="resetForm"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
// import branchesService from "@/core/services/organization-service/branches.service";
// import otherService from "@/core/services/other-service/other.service";
import underwritingService from "@/core/services/underwriting/underwriting.service";
import SearchSelectInput from "@/components/Inputs/_base-search-select-input.vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
export default Vue.extend({
  name: "underwriting-general",
  components: {
    SearchSelectInput,
    CreateFilter: () => 
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      )
  },
  computed: {
    userIsNotAuthorised() {
      return this.errorCode == 409;
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanAssign() {
      const underwriting = this.user.scopes.find(item => item.scopeName === Role.Underwrting);
      const { permissionsList } = underwriting;
      return permissionsList.CanAssignUnderwriters;
    },
  },
  data: function () {
    return {
      content_header: {
        title: "Underwriter General Dashboard",
        subTitle:
          "Setup your underwriting queues here to auto-assign loan applications to underwriters.",
      },
      approverTypes: ["PrimaryApproval", "SecondaryApproval "],
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      allQueues: [],

      allUsers: [
        {
          userKey: "d3ba10d18dc14da78f890e13b4c8e358",
          branchKey: "6b4bcdad5ea94ec5906188fa9179562b",
          apiConsumerType: "--",
          userEmail: "TDJ8W@gmail.com",
          userFirstName: "R8CAE",
          userLastName: "AJ5O3",
        },
        {
          userKey: "5c0d7a4a188d4ea7986bc5b9f897f32b",
          branchKey: "6b4bcdad5ea94ec5906188fa9179562b",
          apiConsumerType: "--",
          userEmail: "TDJ8W@gmail.com",
          userFirstName: "R8CAE",
          userLastName: "AJ5O3",
        },
      ],

      allLoans: [],
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      isDeactivating: false,

      fetchModel: {
        isAssigned: true,
        pageIndex: 1,
        pageSize: 20,
      },

      fetchAccount: {
        isAssigned: true,
        filters: [],
        pageIndex: 1,
        currentPage: 1,
        pageSize: 20,
        isFromFilter: false,
        filterCategory: "",
      },

      showFilterModal: false,
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,

      form: {
        secondaryApprover: null,
        primaryApprover: null,
        approverType: null,
        approverUserKey: "",
        loanAccountKeys: [],
        loanAccountKey: "",
        queueKey: "",
      },

      reassignForm: {
        taskKey: "",
        taskKeys: [],
        userKey: "",
        accessUserKey: "",
      },

      reassignFormErr: {
        taskKey: "",
        userKey: "",
      },

      formErr: {
        queueKey: null
      },

      toSwitchActivation: null,
      switchActivationDisplay: null, // ACTIVATE DEACTIVATE
      toDelete: null,

      selectedLoans: [],
      multiSwitchActivationDisplay: null,
      singleSelectedLoan: null,
      isMultiple: false,

      hasSecondaryApproval: false,
      errorCode: '',
      errorResponse: '',
      formCleared: false,
    };
  },
  watch: {
    paginations() {
      this.filterText = this.paging.filterText ? this.paging.filterText.replace(/_/g, " ") : "";
      this.filterEdit = this.paging.filters ? this.paging.filters : [];
    }
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    // sendFilter(value) {
    //   const filterArray = value.map(obj => {
    //     const payload = {};

    //     payload.filterFieldName = obj.filterName;
    //     payload.filterFieldOperator = obj.filterOp;

    //     if (obj.value1 === '' || obj.value1 === null) {
    //       payload.filterFieldValues = [obj.value];
    //       return payload;
    //     }
    //     payload.filterFieldValues = [obj.value, obj.value1];
    //     return payload;
    //   });
    //   this.fetchModel.filters = filterArray;
    //   this.fetchModel.pageIndex = 1;
    //   this.fetchModel.pageSize = 10;
    //   this.fetchModel.filterCategory = "group";
    //   this.getFilteredGroups();
    // },
    async sendFilter(value) {
      const filterArray = value.map(obj => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === '' || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchAccount.filters = filterArray;
      this.fetchAccount.pageIndex = 1;
      this.fetchAccount.pageSize = 10;
      this.fetchAccount.filterCategory = "underwriting_general";
      this.fetchAccount.isFromFilter = true;
      const res = await underwritingService.getLoansWithUnderwritingInfo(
        this.fetchAccount
      );
      
      // await this.getLoansWithUnderwritingInfo();
      // const res = await underwritingService.getLoansWithUnderwritingInfo(
      //   this.fetchAccount
      // );
      if(res.status) {
        this.alertSuccess("Successfully applied filter");
        const loans = res.data.data;

        loans.forEach((x) => {
          x.selected = false;
        });
        this.selectedLoans = [];

        this.allLoans = loans;
        const data = res.data;
        this.paging = { 
          ...data,
          pageIndex: data.currentPage,
        };

        this.isLoading = false;
        this.filterText = res.data.filterText.replace(/_/g, " ");
        this.filterEdit = res.data.filters;
      } else {
        this.alertError(res.data.message);
      }
    },
    reloadFilter() {
      this.deleteFilter()
      this.fetchAccount.isFromFilter = false;
      this.fetchAccount.filterCategory = "";
      this.fetchAccount.pageSize = 20;
      this.fetchAccount.filters = [];
      this.fetchAccount.pageIndex = 1;
      this.getLoansWithUnderwritingInfo();
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    async getLoansWithUnderwritingInfo() {
      try {
        this.allLoans = [];
        this.isLoading = true;
        const res = await underwritingService.getLoansWithUnderwritingInfo(
          this.fetchAccount
        );
        const loans = res.data.data.items || res.data.data;

        loans.forEach((x) => {
          x.selected = false;
        });
        this.selectedLoans = [];
        this.allLoans = loans;
        this.paging = this.fetchAccount.isFromFilter ? res.data : res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data;
        this.errorCode = e.response.status;
        this.errorResponse = e.response.data;
        this.alertError(error);
      }
    },

    async getQueueSettings() {
      try {
        this.allQueues = [];
        this.isLoading = true;
        const payload = {
          pageIndex: 1,
          pageSize: 1000,
          linkedEntityKey: [],
          queueType: "Underwriting",
          parameterLinkOperator: "And",
          queueStatus: "Active",
          approvalUsers: [],
          approvalLevel: []
        }
        const res = await underwritingService.getQueueSettings(
          payload
        );

        this.allQueues = res.data.data.items;
        this.isLoading = false;
      }
      catch (e) {
        this.isLoading = false;
        const error = e.response.data;
        this.errorCode = e.response.status;
        this.errorResponse = e.response.data;
        this.alertError(error);
      }
    },

    saveAssignForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          if (this.isMultiple) {
            this.form.loanAccountKeys = this.selectedLoans.map(
              (x) => x.loanAccountKey
            );
          } else {
            this.form.loanAccountKeys = [
              this.singleSelectedLoan.loanAccountKey,
            ];
          }
          this.form.loanAccountKey = this.singleSelectedLoan.loanAccountKey;
          await underwritingService.assignTasksToUnderwriter(this.form);
          this.getLoansWithUnderwritingInfo();
          this.clearForm();
          document.getElementById("assignModalCloseButton").click();
          this.alertSuccess("Assignment successful.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },

    saveReAssignForm: async function () {
      // e.preventDefault();
      // if (this.validateForm()) {
      try {
        this.isSaving = true;

        if (this.isMultiple) {
          this.form.loanAccountKeys = this.selectedLoans.map(
            (x) => x.loanAccountKey
          );
          this.reassignForm.taskKeys = this.selectedLoans.map(
            (x) => x.taskKey
          );
        } else {
          this.form.loanAccountKeys = [this.singleSelectedLoan.loanAccountKey];
          this.reassignForm.taskKey = this.singleSelectedLoan.taskKey;
        }

        // if (this.isMultiple) {
        //   this.form.loanAccountKeys = this.selectedLoans.map(
        //     (x) => x.loanAccountKey
        //   );
        // } else {
        //   this.form.loanAccountKeys = [this.singleSelectedLoan.loanAccountKey];
        // }

        // if (
        //   this.singleSelectedLoan.approvalType.toLowerCase() ===
        //   "primary approval"
        // ) {
        //   this.form.primaryApprover = this.form.approverUserKey;
        // } else {
        //   this.form.secondaryApprover = this.form.approverUserKey;
        // }

        await underwritingService.reassignTasksToUnderwriter(this.reassignForm);
        this.getLoansWithUnderwritingInfo();
        this.clearForm();
        document.getElementById("reassignModalCloseButton").click();
        this.alertSuccess("Reassignment successful.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e?.response?.data?.message || e?.response?.data?.data;
        this.alertError(error);
      }
      // }
      // else {
      //   this.alertWarning("Some form fields are required/have errors.");
      // }
    },
    /*saveAssignFormMultiple: async function(e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          this.form.loanAccountKeys = this.selectedLoans.map(
            x => x.loanAccountKey
          );
          await underwritingService.assignTasksToUnderwriter(this.form);
          this.getLoansWithUnderwritingInfo();
          this.clearForm();
          document.getElementById("assignModalCloseButton").click();
          this.alertSuccess("Assignment successful.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    saveReassignFormMultiple: async function(e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          this.form.loanAccountKeys = this.selectedLoans.map(
            x => x.loanAccountKey
          );
          await underwritingService.assignTasksToUnderwriter(this.form);
          this.getLoansWithUnderwritingInfo();
          this.clearForm();
          document.getElementById("reassignModalCloseButton").click();
          this.alertSuccess("Reassignment successful.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },
    saveEditForm: async function(e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await underwritingService.update(this.form);
          this.getLoansWithUnderwritingInfo();
          this.clearForm();
          document.getElementById("editFormCloseButton").click();
          this.alertSuccess("Underwriting updated.");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message || e.response.data.data;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required/have errors.");
      }
    },*/
    setUpForm(singleSelectedLoan, isMultiple) {
      this.clearForm();
      this.singleSelectedLoan = singleSelectedLoan;
      this.isMultiple = isMultiple;
      this.formCleared = false;
    },
    validateForm() {
      this.clearFormValidation();
      if (this.form.queueKey) {
        return true;
      }
      // for (const key in this.formErr) {
      //   if (Object.hasOwnProperty.call(this.formErr, key)) {
      //     if (
      //       this.form[key] == null ||
      //       this.form[key] == undefined ||
      //       this.form[key].trim() == ""
      //     ) {
      //       this.formErr[key] = true;
      //     }
      //   }
      // }
      return false;
    },
    clearForm() {
      this.form = {
        primaryApprover: null,
        secondaryApprover: null,
        approverType: null,
        loanAccountKeys: [],
        approverUserKey: null,
        loanAccountKey: "",
        queueKey: "",
      };
      this.formCleared = true;
      this.hasSecondaryApproval = false;
    },
    resetForm() {
      this.form = {
        primaryApprover: null,
        secondaryApprover: null,
        approverType: null,
        loanAccountKeys: [],
        approverUserKey: null,
        loanAccountKey: "",
        queueKey: "",
      };
      this.formCleared = true;
      this.hasSecondaryApproval = false;
    },
    clearFormValidation() {
      this.formErr = {
        primaryApprover: null,
        secondaryApprover: null,
        approverType: null,
      };
    },

    /*
    setSwitchActivationParams(queueKey, display) {
      this.toSwitchActivation = queueKey;
      this.switchActivationDisplay = display;
    },
    setMultiSwitchActivationParams(display) {
      this.multiSwitchActivationDisplay = display;
    },
    switchActivationFunc: async function() {
      switch (this.switchActivationDisplay) {
        case "ACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: [this.toSwitchActivation],
              status: "Active"
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Activated.");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        case "DEACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: [this.toSwitchActivation],
              status: "Active"
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("activationCloseButton").click();
            this.alertSuccess("Deactivated");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        default:
          break;
      }
    },
    setDeleteQueueId(queueKey) {
      this.toDelete = queueKey;
    },
    deleteQueue: async function() {
      try {
        this.isSaving = true;
        await underwritingService.delete(this.toDelete);
        this.getUnderwritings();
        document.getElementById("deleteCloseButton").click();
        this.alertSuccess("Deleted.");
        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.message || e.response.data.data;
        this.alertError(error);
      }
    },*/
    switchShowAssigned() {
      this.fetchAccount.isAssigned = !this.fetchAccount.isAssigned;
      this.getLoansWithUnderwritingInfo();
    },
    // Selection
    toggleSelected(loan) {
      const index = this.selectedLoans.findIndex(
        (x) => x.loanAccountKey == loan.loanAccountKey
      );
      if (index == -1) {
        this.selectedLoans.push(loan);
      } else {
        this.selectedLoans.splice(index, 1);
      }
    },

    changeSelectedStatus: async function () {
      let keys = this.selectedLoans.map((x) => x.loanAccountKey);
      switch (this.multiSwitchActivationDisplay) {
        case "ACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: keys,
              status: "Active",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("multiActivationCloseButton").click();
            this.alertSuccess("Activated.");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        case "DEACTIVATE":
          try {
            this.isSaving = true;
            const model = {
              queueKeys: keys,
              status: "Inactive",
            };
            await underwritingService.changeQueueStatuses(model);
            this.getUnderwritings();
            document.getElementById("multiActivationCloseButton").click();
            this.alertSuccess("Deactivated");
            this.isSaving = false;
          } catch (e) {
            this.isSaving = false;
            const error = e.response.data.message || e.response.data.data;
            this.alertError(error);
          }
          break;
        default:
          break;
      }
    },

    // Alerts
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    print(user) {
      localStorage.setItem("print", user);
    },
    // Paging
    changePage(pageNum) {
      this.fetchAccount.pageIndex = pageNum;
      this.fetchAccount.currentPage = pageNum;
      this.getLoansWithUnderwritingInfo();
    },
    stepPage(direction) {
      if (direction === "next") {
        this.fetchAccount.pageIndex = this.paging.pageIndex ? this.paging.pageIndex + 1 : this.paging.currentPage + 1;
        this.getLoansWithUnderwritingInfo();
        return;
      }
      if (direction === "previous") {
        this.fetchAccount.pageIndex = this.paging.pageIndex ? this.paging.pageIndex - 1 : this.paging.currentPage - 1;
        this.getLoansWithUnderwritingInfo();
        return;
      }
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        currentPage: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
  },
  async created() {
    this.getLoansWithUnderwritingInfo();
    this.getQueueSettings();
  },
  filters: {
    formatDatePipe: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    moment: function (date) {
      return date != null ? moment(date).format("Do-MMM-YYYY") : "";
    },
    formatCurrencyPipe: function (number) {
      if (number == null || number == undefined || number == "") return "0.00";
      const n = Number(number);
      return n.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    formatEnumToProper: function (str) {
      let words = str.replace("_", " ");
      let separateWord = words.toLowerCase().split(" ");
      for (var i = 0; i < separateWord.length; i++) {
        separateWord[i] =
          separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1).toLowerCase();
      }
      return separateWord.join(" ");
    },
    pascalCaseToString: function (str) {
      if (str == null || str == undefined || str.length == 0) return "";

      let output = "";
      let start = true;
      for (const c of str) {
        if (start) {
          start = false;
          output += c.toUpperCase();
          continue;
        }
        if (c == c.toLowerCase()) {
          output += c;
        } else {
          output += ` ${c.toLowerCase()}`;
        }
      }
      return output;
    },
    fromNow: function (date) {
      return moment(date).fromNow();
    },
  },
});
</script>
<style lang="scss" scoped>
.underwriter-form {
  &.form__item {
    margin-bottom: 0;
  }
}
.text--small {
  font-size: 1rem !important;
}
</style>
