import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";
import LoanService from "@/core/services/api.loan.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "underwriting";
const resourceName = process.env.VUE_APP_API_BASE_GENERAL_URL;
const underwritingService = {
  get(model) {
    // return ApiService.get(resource, "");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/queueSettings`, model);
    }
  },
  getByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, key);
    }
  },
  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(resource, data);
    }
  },
  update(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(resource, data);
    }
  },
  // activate(key) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     return ApiService.put(`${resource}/${key}/Active/status`, null);
  //   }
  // },
  // deactivate(key) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     return ApiService.put(`${resource}/${key}/Inactive/status`, null);
  //   }
  // },
  changeQueueStatuses(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/status`, model);
    }
  },

  delete(keys) {
    let paramStr = keys
      .map((x) => "queueKeys=" + encodeURIComponent(x))
      .join("&");
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(`${resource}?${paramStr}`);
    }
  },

  getUnderwritingTasks(model) {
    model.userKey = "5c0d7a4a188d4ea7986bc5b9f897f32b";
    if (JwtService.getToken()) {
      ApiService.setHeader();
      if (model.isFromFilter) return ApiService.post(`GeneralFilter/ApplyFilter`, model);
      return ApiService.post(`${resource}/tasks`, model);
    }
  },
  changeTaskStatus(model) {
    model.accessUserKey = "5c0d7a4a188d4ea7986bc5b9f897f32b";
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resource}/task/status`, model);
    }
  },
  offerAcceptance(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return LoanService.post(`LoanAccount/loan-sendoffer-approveoverride`, model);
    }
  },
  getUnderwritingTaskByKey(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(`${resource}/task`, key);
    }
  },
  getLoansWithUnderwritingInfo(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      if (model.isFromFilter) return ApiService.post(`GeneralFilter/ApplyFilter`, model);
      return ApiService.post(`${resource}/tasks/loans`, model);
    }
  },
  getQueueSettings(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resourceName}Underwriting/queueSettings`, model);
    }
  },
  assignTasksToUnderwriter(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/task/underwriter`, model);
    }
  },
  reassignTasksToUnderwriter(model) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.put(`${resourceName}Underwriting/task/reassigntasktoanotheruser`, model);
    }
  },
};

export default underwritingService;
